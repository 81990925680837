<template>
  <div ref="sectionRef" class="relative mx-auto overflow-hidden">
    <div class="immersive-image mx-auto block h-full overflow-hidden">
      <Picture
        ref="imgRef"
        :data="data?.media!"
        loading="lazy"
        class="absolute left-0 top-0 block h-full w-full overflow-hidden"
        pictureClass="h-full w-full"
        imgClass="object-cover lg:max-w-none absolute left-0 bottom-0 w-full h-[130%]"
        :observerOptions="{
          threshold: 0
        }"
      />
    </div>
    <div
      class="grid-default px-page relative min-h-full w-full pb-16 pt-36 text-white md:pb-24 md:pt-96"
    >
      <div class="col-span-12 sm:col-span-10 md:col-start-2">
        <div class="flex h-full flex-col justify-between">
          <div class="flex flex-col gap-y-12 md:gap-y-24">
            <div
              v-for="(title, idx) in data.titles"
              :key="idx"
              ref="titleRefs"
              class="immersive-title opacity-40"
            >
              <Heading :size="3" generic class="transform-gpu">
                {{ title.title }}
              </Heading>
            </div>
          </div>
          <div v-if="Array.isArray(data.button) && data.button.length" class="mt-20 md:mt-30">
            <Button
              :label="data.button[0].label"
              :to="
                data.button[0].internalLink !== null
                  ? data.button[0].anchorLink!
                    ? `${localePathByType(
                        data.button[0].internalLink!.__typename as string,
                        data.button[0].internalLink!.slug as string,
                        data.button[0].internalLink!.__typename === ('ArticleRecord' as string) &&
                          data.button[0].internalLink!.category.slug
                      )}${data.button[0].anchorLink!}`
                    : localePathByType(
                        data.button[0].internalLink!.__typename as string,
                        data.button[0].internalLink!.slug as string,
                        data.button[0].internalLink!.__typename === ('ArticleRecord' as string) &&
                          data.button[0].internalLink!.category.slug
                      )
                  : data.button[0].externalLink
              "
              :target="data.button[0].externalLink ? '_blank' : undefined"
              variant="solid"
              color="white"
              size="lg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SectionImmersiveTitleRecord } from '~/types/generated'

const { localePathByType } = useLocalePathByType()
const sectionRef = ref<HTMLElement | null>(null)

defineProps({
  data: {
    type: Object as PropType<SectionImmersiveTitleRecord>,
    default: () => {}
  }
})

const twTitle = ref<null | gsap.core.Tween>(null)
const twImage = ref<null | gsap.core.Tween>(null)

const titleRefs = ref([])
const imgRef = ref<any | null>(null)

onNuxtReady(async () => {
  await nextTick(() => {
    gsap.registerPlugin(ScrollTrigger)

    if (titleRefs.value && imgRef.value) {
      titleRefs.value.forEach((el, idx) => {
        twTitle.value = gsap.to(el, {
          opacity: 1,
          ease: 'none',
          scrollTrigger: {
            id: `immersiveTitle-${idx + 1}`,
            trigger: el,
            start: 'top 60%'
          }
        })
      })

      twImage.value = gsap.to(imgRef.value.imgRef, {
        y: '25%',
        scrollTrigger: {
          scrub: true,
          id: 'parallaxImage',
          trigger: sectionRef.value,
          start: 'top bottom'
        }
      })
    }

    ScrollTrigger.refresh()
  })
})

onUnmounted(() => {
  twTitle.value?.kill()
  twImage.value?.kill()
})
</script>
